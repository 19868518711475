import React from "react"
import {Link, graphql} from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import { Disqus } from 'gatsby-plugin-disqus'
import logo from "../assets/images/logo.svg";

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Categories from "../components/blog/categories";
import useSiteMetadata from "../components/site-data";
const BlogPostTemplate = ({
                            data: {previous, next, post},
                            location
                          }) => {

  const { siteUrl } = useSiteMetadata();

  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``
  }

  const author = post.author.node.firstName + ' ' + post.author.node.lastName;
  const username = post.author.node.slug;
  const postContent = post.content ? parse(post.content) : null;

  const seo = post.seo;

  const title = seo?.title ? seo.title : post.title;
  const description = seo?.metaDesc ? seo.metaDesc : post.excerpt.replace(/<[^>]*>?/gm, '');
  const ogDescription = seo?.opengraphDescription ? seo.opengraphDescription : description;
  const ogTitle = seo?.opengraphTitle ? seo.opengraphTitle : title;
  const ogType = seo?.opengraphType ? seo.opengraphType : 'website';
  const ogPublished = seo?.opengraphPublishedTime ? seo.opengraphPublishedTime : post.creationDate;
  const ogModified = seo?.opengraphModifiedTime ? seo.opengraphModifiedTime : post.modified;
  const ogImage = seo?.opengraphImage?.localFile?.url ? seo.opengraphImage.localFile.url : featuredImage.fluid?.src;
  const ogImageAlt = seo?.opengraphImage?.altText ? seo.opengraphImage.altText : featuredImage.alt;

  const schema = {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": siteUrl + location.pathname
      },
      "headline": title,
      "description": description,
      "image": featuredImage.fluid?.src,
      "author": {
        "@type": "Person",
        "name": post.author.node.firstName + ' ' + post.author.node.lastName
      },
      "publisher": {
        "@type": "Organization",
        "name": "Derave Software",
        "logo": {
          "@type": "ImageObject",
          "url": siteUrl + logo
      }
    },
      "datePublished": post.creationDate,
      "dateModified": post.modified
  }

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        schema={schema}
        image={siteUrl + featuredImage.fluid?.src}
        meta={[
          {
            name: `description`,
            content: description
          },
          {
            property: `og:title`,
            content: ogTitle
          },
          {
            property: `og:description`,
            content: ogDescription
          },
          {
            property: `og:type`,
            content: ogType
          },
          {
            property: `article:published_time`,
            content: ogPublished
          },
          {
            property: `article:modified_time`,
            content: ogModified
          },
          {
            property: `og:image`,
            content: ogImage
          },
          {
            property: `og:image:alt`,
            content: ogImageAlt
          },
          {
            name: `twitter:card`,
            content: 'summary'
          },
          {
            name: `twitter:title`,
            content: ogTitle
          },
          {
            name: `twitter:description`,
            content: ogDescription
          }
        ]}
      />

      <article className="single-article">
        <div className="single-article__image-wrapper">

          <Image
            fluid={featuredImage.fluid}
            alt={featuredImage.alt}
          />


        </div>

        <div className="container-fluid">
          <div className="row columns-8">
            <div className="single-article__bg"/>
            <div className="single-article__hashtags">
              <Categories terms={post.terms.nodes}/>
            </div>
            <h1 className="single-article__title">{parse(post.title)}</h1>
            <span className="single-article__author">
                <span className="single-article__prefix">By</span>
              <Link to={'/author/' + username}>  {author}</Link>
            </span>

            <div className="single-article__main">
              <div className="single-article__content content">
                  {postContent}
              </div>

              {post.post_acfs.ctaLink && <div className="single-article__cta">
                <Link to={post.post_acfs.ctaLink} className="btn-outline">{post.post_acfs.ctaText}</Link>
              </div>}
            </div>
            <div className="single-article__right">
              <div className="single-article__share noselect">
                <span>SHARE</span>
                <div className="single-article__share-icon single-article__share-icon--fb">
                  <a rel="noopener nofollow" title="Share on Facebook" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${siteUrl}${location.pathname}`}/>
                </div>

                <div className="single-article__share-icon single-article__share-icon--linkedin">
                  <a rel="noopener nofollow" title="Share on LinkedIn" target="_blank" href={`https://www.linkedin.com/sharing/share-offsite/?url=${siteUrl}${location.pathname}`}/>
                </div>

                <div className="single-article__share-icon single-article__share-icon--twitter">
                  <a rel="noopener nofollow" title="Share on Twitter" target="_blank" href={`https://twitter.com/intent/tweet?text=${siteUrl}${location.pathname}`}/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </article>

      <div className="comments">
        <div className="container-fluid">
          <div className="row columns-8">
            <div className="comments__form">
              <Disqus
                config={
                  {
                    url: location.uri,
                    identifier: post.slug,
                    title: post.title,
                  }
                }
              />
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      uri
      content
      title
      date(formatString: "MMMM DD, YYYY")
      creationDate: date
      modified
      seo {
          title
          metaDesc
          metaKeywords
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          opengraphImage {
            altText
            localFile {
                url
            }
          }
      }
      
      post_acfs {
        ctaLink
        ctaText
      }
      
      author {
        node {
          firstName
          lastName
          slug
        }
      }
      
      terms {
          nodes {
            name
            slug
          }
        }

      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 95, ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
